import C from './constants';
import { fetchUserDetails } from '../components/services/getUserDetailsService';

export function updateIsAuthenticatedFlag(status){
    return {
        type: C.UPDATE_AUTH_FLAG,
        payload: status
    }
}

export function updateUserFetching(status){
    if(status)
        return { type: C.USER_DETAILS_FETCHING }
    else
        return { type: C.CANCEL_USER_DETAILS_FETCHING }
}

export function updateConfig(configInfo) {
    return {
        type: C.UPDATE_CONFIG_INFO,
        payload: configInfo
    }
}

export function updatePreviewTypeConfig(configInfo) {
    return {
        type: C.UPDATE_PREVIEW_TYPE_CONFIG_INFO,
        payload: configInfo
    }
}

export const updateConfigParams = (data) => ({
    type: C.UPDATE_CONFIGPARAMS,
    payload: data
})

export function updateUserInfo(userInfo) {
    return {
        type: C.UPDATE_USER_INFO,
        payload: userInfo
    }
}

export function updateConfigurationApi(status){
    if(status)
        return { type: C.SET_CONFIGURATION_API }
    else
        return { type: C.UNSET_CONFIGURATION_API }
}

export function updateChartsApi(status){
    if(status)
        return { type: C.SET_CHARTS_OVERVIEW_API }
    else
        return { type: C.UNSET_CHARTS_OVERVIEW_API }
}

export function updateWorkfileChartsApi(status){
    if(status)
        return { type: C.SET_WOEKFILE_CHARTS_OVERVIEW_API }
    else
        return { type: C.UNSET_WORKFILE_CHARTS_OVERVIEW_API }
}

export function updateFiltersApi(status){
    if(status)
        return { type: C.SET_FILTERS_API }
    else
        return { type: C.UNSET_FILTERS_API }
}

export function updateWorkfileFiltersApi(status){
    if(status)
        return { type: C.SET_WORKFILE_FILTERS_API }
    else
        return { type: C.UNSET_WORKFILE_FILTERS_API }
}

export function updatePatentsApi(status){
    if(status)
        return { type: C.SET_PATENTS_API }
    else
        return { type: C.UNSET_PATENTS_API }
}

export function updateWorkfilePatentsApi(status){
    if(status)
        return { type: C.SET_WORKFILE_PATENTS_API }
    else
        return { type: C.UNSET_WORKFILE_PATENTS_API }
}

export function updateStartUpApi(status){
    if(status)
        return { type: C.SET_STARTUP_API }
    else
        return { type: C.UNSET_STARTUP_API }
}

export function updateWorkfileStartUpApi(status){
    if(status)
        return { type: C.SET_WORKFILE_STARTUP_API }
    else
        return { type: C.UNSET_WORKFILE_STARTUP_API }
}

export function updatePatentsPageApi(status){
    if(status)
        return { type: C.SET_PATENTS_PAGE_API }
    else
        return { type: C.UNSET_PATENTS_PAGE_API }
}

export function updateWorkfilePatentsPageApi(status){
    if(status)
        return { type: C.SET_WORKFILE_PATENTS_PAGE_API }
    else
        return { type: C.UNSET_WORKFILE_PATENTS_PAGE_API }
}

export function updateCcTaxonomyApi(status){
    if(status)
        return { type: C.SET_CC_TAXONOMY_API }
    else
        return { type: C.UNSET_CC_TAXONOMY_API }
}

export function updateWorkfileCcTaxonomyApi(status){
    if(status)
        return { type: C.SET_WORKFILE_CC_TAXONOMY_API }
    else
        return { type: C.UNSET_WORKFILE_CC_TAXONOMY_API }
}

export function updateCpcTaxonomyApi(status){
    if(status)
        return { type: C.SET_CPC_TAXONOMY_API }
    else
        return { type: C.UNSET_CPC_TAXONOMY_API }
}

export function updateCorporateTreeApi(status){
    if(status)
        return { type: C.SET_CORPORATE_TREE_API }
    else
        return { type: C.UNSET_CORPORATE_TREE_API }
}

export function updateCpcTreeApi(status){
    if(status)
        return { type: C.SET_CPC_TREE_API }
    else
        return { type: C.UNSET_CPC_TREE_API }
}

export function updateCorporateTreeStatus(status){
    if(status)
        return { type: C.SET_CORPORATE_TREE_STATUS }
    else
        return { type: C.UNSET_CORPORATE_TREE_STATUS }
}

export function updateCpcTreeStatus(status){
    if(status)
        return { type: C.SET_CPC_TREE_STATUS }
    else
        return { type: C.UNSET_CPC_TREE_STATUS }
}

export function updateScrollTopFlag(status){
    if(status)
        return { type: C.SET_SCROLL_TOP_FLAG }
    else
        return { type: C.UNSET_SCROLL_TOP_FLAG }
}

export function updateWorkfileScrollTopFlag(status){
    if(status)
        return { type: C.SET_WORKFILE_SCROLL_TOP_FLAG }
    else
        return { type: C.UNSET_WORKFILE_SCROLL_TOP_FLAG }
}

export function isChartsFullDataUptoDate(status){
    if(status)
        return { type: C.SET_ANALYTICS_FULL_DATA_UPTO_DATE }
    else
        return { type: C.UNSET_ANALYTICS_FULL_DATA_UPTO_DATE }
}

export function isWorkfileChartsFullDataUptoDate(status){
    if(status)
        return { type: C.SET_WORKFILE_ANALYTICS_FULL_DATA_UPTO_DATE }
    else
        return { type: C.UNSET_WORKFILE_ANALYTICS_FULL_DATA_UPTO_DATE }
}

export function isAnalyticsPanelUpdated(status){
    if(status)
        return { type: C.UPDATE_ANALYTICS_PANEL_DATA }
    else
        return { type: C.CLEAR_ANALYTICS_PANEL_DATA }
}

export function isPatentsPanelUpdated(status){
    if(status)
        return { type: C.UPDATE_PATENTS_PANEL_DATA }
    else
        return { type: C.CLEAR_PATENTS_PANEL_DATA }
}

export function receivedFreshTaxonomyCC(status){
    if(status)
        return { type: C.SET_FRESH_TAXONOMY_CC }
    else
        return { type: C.UNSET_FRESH_TAXONOMY_CC }
}

export function receivedWorkfileFreshTaxonomyCC(status){
    if(status)
        return { type: C.SET_WORKFILE_FRESH_TAXONOMY_CC }
    else
        return { type: C.UNSET_WORKFILE_FRESH_TAXONOMY_CC }
}

export function receivedFreshTaxonomyCPC(status){
    if(status)
        return { type: C.SET_FRESH_TAXONOMY_CPC }
    else
        return { type: C.UNSET_FRESH_TAXONOMY_CPC }
}

export function isFieldSearchPanelOpened(status){
    if(status)
        return { type: C.ENABLE_FIELD_SEARCH }
    else
        return { type: C.CLOSE_FIELD_SEARCH }
}

export function isWorkfileFieldSearchPanelOpened(status){
    if(status)
        return { type: C.ENABLE_WORKFILE_FIELD_SEARCH }
    else
        return { type: C.CLOSE_WORKFILE_FIELD_SEARCH }
}

export const updateBasicQuery = (data) => ({
    type: C.BASIC_QUERY,
    payload: data
})

export const updateWorkfileBasicQuery = (data) => ({
    type: C.WORKFILE_BASIC_QUERY,
    payload: data
})

export const updateFormattedQuery = (data) => ({
    type: C.FORMATTED_QUERY,
    payload: data
})

export const updateWorkfileFormattedQuery = (data) => ({
    type: C.WORKFILE_FORMATTED_QUERY,
    payload: data
})

export function updateBasicQueryFromFiltersFlag(status){
    if(status)
        return { type: C.SET_BASIC_QUERY_FROM_FILTERS_FLAG }
    else
        return { type: C.UNSET_BASIC_QUERY_FROM_FILTERS_FLAG }
}

export function updateWorkfileBasicQueryFromFiltersFlag(status){
    if(status)
        return { type: C.SET_WORKFILE_BASIC_QUERY_FROM_FILTERS_FLAG }
    else
        return { type: C.UNSET_WORKFILE_BASIC_QUERY_FROM_FILTERS_FLAG }
}

export const updateFieldQuery = (data) => ({
    type: C.FIELD_QUERY,
    payload: data
})
export const updateFieldQueryData = (data) => ({
    type: C.FIELD_QUERY_DATA,
    payload: data
})


export const updateWorkfileFieldQuery = (data) => ({
    type: C.WORKFILE_FIELD_QUERY,
    payload: data
})


export const updateQueryMetaData = (data) => ({
    type: C.UPDATE_QUERY_METADATA,
    payload: data
})

export const updateWorkfileQueryMetaData = (data) => ({
    type: C.UPDATE_WORKFILE_QUERY_METADATA,
    payload: data
})

export function updateCombineQueryFlag(status){
    if(status)
        return { type: C.SET_COMBINE_QUERY_FLAG }
    else
        return { type: C.UNSET_COMBINE_QUERY_FLAG }
}

export function updateWorkfileCombineQueryFlag(status){
    if(status)
        return { type: C.SET_WORKFILE_COMBINE_QUERY_FLAG }
    else
        return { type: C.UNSET_WORKFILE_COMBINE_QUERY_FLAG }
}

export const updateGroupBy = (data) => ({
    type: C.UPDATE_GROUP_BY,
    payload: data
})

export const updateGroupFieldOptions = (data) => ({
    type: C.UPDATE_GROUP_FIELD_OPTIONS,
    payload: data
})

export const updateWorkfileGroupBy = (data) => ({
    type: C.UPDATE_WORKFILE_GROUP_BY,
    payload: data
})

export const updateFilters = (data) => ({
    type: C.UPDATE_FILTERS,
    payload: data
})

export const updateWorkfileFilters = (data) => ({
    type: C.UPDATE_WORKFILE_FILTERS,
    payload: data
})

export const updateFiltersDataStatus = (data) => ({
    type: C.UPDATE_FILTERS_DATA_STATUS,
    payload: data
})

export const updateRefPatentDetails = (data) => ({
    type: C.UPDATE_REF_PATENT_DETAILS,
    payload: data
})

export const updateSideBySide = (data) => ({
    type: C.UPDATE_SIDEBYSIDE,
    payload: data
})

export const updateWorkfileFiltersDataStatus = (data) => ({
    type: C.UPDATE_WORKFILE_FILTERS_DATA_STATUS,
    payload: data
})

export const updateCutomizePatentDataStatus = (data) => ({
    type: C.UPDATE_CUSTOMIZE_PATENT_DATA_STATUS,
    payload: data
})

export const updateFiltersCPCCounts = (data) => ({
    type: C.UPDATE_FILTERS_CPC_COUNT,
    payload: data
})

export const updateFiltersCorpCounts = (data) => ({
    type: C.UPDATE_FILTERS_CORP_COUNT,
    payload: data
})

export const updateAppliedTaxonomy = (data) => ({
    type: C.UPDATE_APPLIED_TAXONOMY,
    payload: data
})

export const updateWorkfileAppliedTaxonomy = (data) => ({
    type: C.UPDATE_WORKFILE_APPLIED_TAXONOMY,
    payload: data
})

export const updateDummyAppliedTaxonomy = (data) => ({
    type: C.UPDATE_DUMMY_APPLIED_TAXONOMY,
    payload: data
})

export const updateWorkfileDummyAppliedTaxonomy = (data) => ({
    type: C.UPDATE_WORKFILE_DUMMY_APPLIED_TAXONOMY,
    payload: data
})

export const updateSelectedTaxonomy = (data) => ({
    type: C.UPDATE_SELECTED_TAXONOMY,
    payload: data
})

export function updateApplyFiltersFlag(status){
    if(status)
        return { type: C.SET_APPLY_FILTERS_FLAG }
    else
        return { type: C.UNSET_APPLY_FILTERS_FLAG }
}

export function updateWorkfileApplyFiltersFlag(status){
    if(status)
        return { type: C.SET_WORKFILE_APPLY_FILTERS_FLAG }
    else
        return { type: C.UNSET_WORKFILE_APPLY_FILTERS_FLAG }
}

export const updateAppliedFilters = (data) => ({
    type: C.UPDATE_APPLIED_FILTERS,
    payload: data
})

export const updateWorkfileAppliedFilters = (data) => ({
    type: C.UPDATE_WORKFILE_APPLIED_FILTERS,
    payload: data
})

export const updateUrlFilters = (data) => ({
    type: C.UPDATE_URL_FILTERS,
    payload: data
})

export const updateSelectedFiltersType = (data) => ({
    type: C.UPDATE_SELECTED_FILTERS_TYPE,
    payload: data
})

export const updateFiltersFacetsLimit = (data) => ({
    type: C.UPDATE_FILTERS_FACETS_LIMIT,
    payload: data
})

export const updateWorkfileFiltersFacetsLimit = (data) => ({
    type: C.UPDATE_WORKFILE_FILTERS_FACETS_LIMIT,
    payload: data
})

export const updateChartsOverview = (data) => ({
    type: C.UPDATE_CHARTS_OVERVIEW_DATA,
    payload: data
})

export const updateWorkfileChartsOverview = (data) => ({
    type: C.UPDATE_WORKFILE_CHARTS_OVERVIEW_DATA,
    payload: data
})

export const updateChartsFull = (data) => ({
    type: C.UPDATE_CHARTS_FULL_DATA,
    payload: data
})

export const updateCompanyChartsFull = (data) => ({
    type: C.UPDATE_COMPANY_CHARTS_FULL_DATA,
    payload: data
})

export const updateWorkfileChartsFull = (data) => ({
    type: C.UPDATE_WORKFILE_CHARTS_FULL_DATA,
    payload: data
})

export function updateChartFullScreen(status){
    if(status)
        return { type: C.SET_CHART_FULL_SCREEN }
    else
        return { type: C.UNSET_CHART_FULL_SCREEN }
}

export function updateWorkfileChartFullScreen(status){
    if(status)
        return { type: C.SET_WORKFILE_CHART_FULL_SCREEN }
    else
        return { type: C.UNSET_WORKFILE_CHART_FULL_SCREEN }
}

export const updatePatentsOverview = (data) => ({
    type: C.UPDATE_PATENTS_DATA,
    payload: data
})

export const updateImportPatentData = (data) => ({
    type: C.UPDATE_IMPORT_PATENTS_DATA,
    payload: data
})

export const updateWorkfilePatentsOverview = (data) => ({
    type: C.UPDATE_WORKFILE_PATENTS_DATA,
    payload: data
})

export const updateStartUpData = (data) => ({
    type: C.UPDATE_STARTUP_DATA,
    payload: data
})

export const updateWorkfileStartUpData = (data) => ({
    type: C.UPDATE_WORKFILE_STARTUP_DATA,
    payload: data
})

export const updatePatentsSelectedCount = (data) => ({
    type: C.UPDATE_PATENTS_SELECTED_COUNT,
    payload: data
})

export const updateWorkfilePatentsSelectedCount = (data) => ({
    type: C.UPDATE_WORKFILE_PATENTS_SELECTED_COUNT,
    payload: data
})

export const updatePatentSortOption = (data) => ({
    type: C.UPDATE_PATENTS_SORT_OPTION,
    payload: data
})

export const updateWorkfilePatentSortOption = (data) => ({
    type: C.UPDATE_WORKFILE_PATENTS_SORT_OPTION,
    payload: data
})

export const updateWorkfileRatingMetaData = (data) => ({
    type: C.UPDATE_WORKFILE_WORKFILE_RATING_METADATA,
    payload: data
})

export const updateWorkfileId = (data) => ({
    type: C.UPDATE_WORKFILE_WORKFILE_ID,
    payload: data
})

export const updateWorkfileDetails = (data) => ({
    type: C.UPDATE_WORKFILE_WORKFILE_DETAILS,
    payload: data
})

export const updatePageNumber = (data) => ({
    type: C.UPDATE_PATENTS_PAGE_NUMBER,
    payload: data
})

export const updateWorkfilePageNumber = (data) => ({
    type: C.UPDATE_WORKFILE_PATENTS_PAGE_NUMBER,
    payload: data
})

export const updatePatentDetailIndex = (data) => ({
    type: C.UPDATE_PATENT_DETAIL_INDEX,
    payload: data
})

export const updateWorkfilePatentDetailIndex = (data) => ({
    type: C.UPDATE_WORKFILE_PATENT_DETAIL_INDEX,
    payload: data
})

export const updateDocumentUrlBeforeDetails = (data) => ({
    type: C.UPDATE_DOCUMENT_URL_BEFORE_DETAILS,
    payload: data
})

export function updateselectAllPages(status){
    if(status)
        return { type: C.SET_SELECT_ALL_PAGES }
    else
        return { type: C.UNSET_SELECT_ALL_PAGES }
}

export function updateWorkfileselectAllPages(status){
    if(status)
        return { type: C.SET_WORKFILE_SELECT_ALL_PAGES }
    else
        return { type: C.UNSET_WORKFILE_SELECT_ALL_PAGES }
}

export function updateSelectAllInCurrentPage(status){
    if(status)
        return { type: C.SET_SELECT_ALL_CURRENT_PAGE }
    else
        return { type: C.UNSET_SELECT_ALL_CURRENT_PAGE }
}

export function updateWorkfileSelectAllInCurrentPage(status){
    if(status)
        return { type: C.SET_WORKFILE_SELECT_ALL_CURRENT_PAGE }
    else
        return { type: C.UNSET_WORKFILE_SELECT_ALL_CURRENT_PAGE }
}

export const updateSelectedPages = (data) => ({
    type: C.UPDATE_SELECTED_PAGES,
    payload: data
})

export const updateWorkfileSelectedPages = (data) => ({
    type: C.UPDATE_WORKFILE_SELECTED_PAGES,
    payload: data
})

export const updateSynonymsData = (data) => ({
    type: C.UPDATE_SYNONYMS_DATA,
    payload: data
})

export const updateWorkfileSynonymsData = (data) => ({
    type: C.UPDATE_WORKFILE_SYNONYMS_DATA,
    payload: data
})

export const updateSynonymsAppliedData = (data) => ({
    type: C.UPDATE_SYNONYMS_APPLIED_DATA,
    payload: data
})

export const updateWorkfileSynonymsAppliedData = (data) => ({
    type: C.UPDATE_WORKFILE_SYNONYMS_APPLIED_DATA,
    payload: data
})

export const updateSemanticsData = (data) => ({
    type: C.UPDATE_SEMANTICS_DATA,
    payload: data
})

export const updateWorkfileSemanticsData = (data) => ({
    type: C.UPDATE_WORKFILE_SEMANTICS_DATA,
    payload: data
})

export const updateTaxonomyCC = (data) => ({
    type: C.TAXONOMY_CC,
    payload: data
})

export const updateWorkfileTaxonomyCC = (data) => ({
    type: C.WORKFILE_TAXONOMY_CC,
    payload: data
})

export const updateTaxonomyCPC = (data) => ({
    type: C.TAXONOMY_CPC,
    payload: data
})

export const cursorDetails = (data) => ({
    type: C.CURSOR_DETAILS,
    payload: data
})

export const workfileCursorDetails = (data) => ({
    type: C.WORKFILE_CURSOR_DETAILS,
    payload: data
})

export const addError = (message) => ({
    type: C.ADD_ERROR,
    payload: message
})

export const clearError = () => ({
    type: C.CLEAR_ERROR,
    payload: []
})

export function updateErrorNotificationFlag(status){
    if(status)
        return { type: C.SET_ERROR_NOTIFICATION }
    else
        return { type: C.UNSET_ERROR_NOTIFICATION }
}

export const addExportFile = (data) => ({
    type: C.ADD_EXPORT_FILE,
    payload: data
})

export const removeExportFile = (data) => ({
    type: C.REMOVE_EXPORT_FILE,
    payload: data
})

export const addDashboardFile = (data) => ({
    type: C.ADD_DASHBOARD_FILE,
    payload: data
})

export const removeDashboardFile = (data) => ({
    type: C.REMOVE_DASHBOARD_FILE,
    payload: data
})

export const userDetailsServiceAction = token => dispatch => {
    fetchUserDetails(token, dispatch);
}

export const updateSearchFlagFromHistory = (data) => ({
    type: C.SET_SEARCH_FLAG_FROM_HISTORY,
    payload: data
})

export const updateFiltersFromHistory = (data) => ({
    type: C.SET_FILTERS_FROM_HISTORY,
    payload: data
})

export const updateTaxonomyFromHistory = (data) => ({
    type: C.SET_TAXONOMY_FROM_HISTORY,
    payload: data
})

export const updateInitialLoad = (data) => ({
    type: C.SET_INITIAL_LOAD,
    payload: data
})

export const updateWorkfileInitialLoad = (data) => ({
    type: C.SET_WORKFILE_INITIAL_LOAD,
    payload: data
})

export const updateCreateAlertData = (data) => ({
    type: C.UPDATE_CREATE_ALERT_DATA,
    payload: data
})

export const updateShowFullChartsFlag = (data) => ({
    type: C.UPDATE_SHOW_FULLCHARTS_FLAG,
    payload: data
})

export const updatePatentPositionRef = (data) => ({
    type: C.UPDATE_PATENT_POSITION_REF,
    payload: data
})

export const updateWorkfilePatentPositionRef = (data) => ({
    type: C.UPDATE_WORKFILE_PATENT_POSITION_REF,
    payload: data
})

export const updateHighlightedKeyWords = (data) => ({
    type: C.UPDATE_HIGHLIGHTED_KEYWORDS,
    payload: data
})

export const updateWorkfileHighlightedKeyWords = (data) => ({
    type: C.UPDATE_WORKFILE_HIGHLIGHTED_KEYWORDS,
    payload: data
})

export const updateHighlightedWordForms = (data) => ({
    type: C.UPDATE_HIGHLIGHTED_WORDFORMS,
    payload: data
})

export const updateHighlightOnOffFlag = (data) => ({
    type: C.UPDATE_HIGHLIGHT_ON_OFF_FLAG,
    payload: data
})

export const updateViewedPatents = (data) => ({
    type: C.UPDATE_VIEWED_PATENTS,
    payload: data
})

export const updateSaveToWorkfileApi = (data) => ({
    type: C.UPDATE_SAVE_TO_WORKFILE_API,
    payload: data
})

export const updateWordformsData = (data) =>({
    type: C.UPDATE_WORDFORMS_DATA,
    payload: data
})

export const updatePatentsStatsData = (data) => ({
    type: C.UPDATE_PATENTS_STATS_DATA,
    payload: data
})

export const updateWorkfilePatentsStatsData = (data) => ({
    type: C.UPDATE_WORKFILE_PATENTS_STATS_DATA,
    payload: data
})

export const updateHiddenCitationsData = (data) => ({
    type: C.UPDATE_HIDDEN_CITATIONS_DATA,
    payload: data
})

export const updateHiddenCitnCurLevel = (data) => ({
    type: C.UPDATE_HIDDEN_CITN_CUR_LEVEL,
    payload: data
})

export const updateHiddenCitnEachLevel = (data) => ({
    type: C.UPDATE_HIDDEN_CITN_EACH_LEVEL,
    payload: data
})

export const updateHiddenCitnPageNumber = (data) => ({
    type: C.UPDATE_HIDDEN_CITN_PAGE_NUMBER,
    payload: data
})

export const updateHiddenCitnPatentsSelectedCount = (data) => ({
    type: C.UPDATE_HIDDEN_CITN_PATENTS_SELECTED_COUNT,
    payload: data
})

export function updateHiddenCitnselectAllPages(status){
    if(status)
        return { type: C.SET_HIDDEN_CITN_SELECT_ALL_PAGES }
    else
        return { type: C.UNSET_HIDDEN_CITN_SELECT_ALL_PAGES }
}


export function updateHiddenCitnSelectAllInCurrentPage(status){
    if(status)
        return { type: C.SET_HIDDEN_CITN_SELECT_ALL_CURRENT_PAGE }
    else
        return { type: C.UNSET_HIDDEN_CITN_SELECT_ALL_CURRENT_PAGE }
}

export const updateHiddenCitnSelectedPages = (data) => ({
    type: C.UPDATE_HIDDEN_CITN_SELECTED_PAGES,
    payload: data
})

export const updateMissingPatentsData = (data) =>({
    type: C.UPDATE_MISSING_PATENTS_DATA,
    payload: data
})

export function updateApplyMissingPatentsFlag(status){
    if(status)
        return { type: C.SET_APPLY_MISSING_PATENTS_FLAG }
    else
        return { type: C.UNSET_APPLY_MISSING_PATENTS_FLAG }
}

export const updateOrderingData = (data) => ({
    type: C.UPDATE_ORDERING_DATA,
    payload: data
})

export const updateGroupSort = (data) => ({
    type: C.UPDATE_GROUP_SORT,
    payload: data
})

export const updateAddUserSynonymsFlag = (data) => ({
    type: C.UPDATE_ADD_USER_SYNONYMS_FLAG,
    payload: data
})

export const updateRunClonedQueryFlag = (data) => ({
    type: C.UPDATE_RUN_CLONED_QUERY_FLAG,
    payload: data
})

export function updateDevControls(data) {
    return {
        type: C.UPDATE_DEV_CONTROLS,
        payload: data
    }
}

export const updateSessionGroupData = (data) =>({
    type: C.UPDATE_SESSION_GROUP_DATA,
    payload: data
})

export const updateFetchFilterKeys = (data) =>({
    type: C.UPDATE_FETCH_FILTER_KEYS,
    payload: data
})

export const updateUserGroups = (data) =>({
    type: C.UPDATE_USER_GROUPS,
    payload: data
})

export const updateProjectId = (data) => ({
    type: C.UPDATE_PROJECT_ID,
    payload: data
})

export const updateTaxonomyPreviousActiveTab = (data) => ({
    type: C.UPDATE_TAXONOMY_PREVIOUS_ACTIVE_TAB,
    payload: data
})

export const updateDocumentMaxScore = (data) => ({
    type: C.UPDATE_DOCUMENT_MAX_SCORE,
    payload: data
})

export const updateLanguage = (data) => ({
    type: C.UPDATE_LANGUAGE,
    payload: data
})

export const updateDocumentsPath = (data) => ({
    type: C.UPDATE_DOCUMENTSPATH,
    payload: data
})

export const updateExpandCountHistory = (data) => ({
    type: C.UPDATE_EXPAND_COUNT_HISTORY,
    payload: data
})

export const updateYearTableFilterFlag = (data) => ({
    type: C.UPDATE_YEAR_TABLE_FILTER_FLAG,
    payload: data
})
export const updateDataSourceData = (data) => ({
    type: C.UPDATE_DATASOURCE_DATA,
    payload: data
})

export const updateDocumentType = (data) => ({
    type: C.UPDATE_DOCUMENT_TYPE,
    payload: data
})

export const updateDetailsViewType = (data) => ({
    type: C.UPDATE_DETAILS_VIEW_TYPE,
    payload: data
})

export const updateConnectorScreen = (data) => ({
    type: C.UPDATE_CONNECTOR_SCREEN,
    payload: data
})

export const updateConnectorConfigParams = (data) => ({
    type: C.UPDATE_CONNECTOR_CONFIGPARAMS,
    payload: data
})

export const updateConnectorConfig = (data) => ({
    type: C.UPDATE_CONNECTOR_CONFIG,
    payload: data
})

export const updateConnectorInfo = (data) => ({
    type: C.UPDATE_CONNECTOR_INFO,
    payload: data
})

export function updateActiveTab(data) {
    return {
        type: C.UPDATE_ACTIVE_TAB,
        payload: data
    }
}

export const updateDocumentTaggingOptions = (data) => ({
    type: C.UPDATE_DOCUMENT_TAGGING_OPTIONS,
    payload: data
})

export const updateShowProjectSettingsFlag = (data) => ({
    type: C.UPDATE_PROJECT_SETTINGS_FLAG,
    payload: data
})

export const updateAppliedSimilarityFilters = (data) => ({
    type: C.UPDATE_APPLIED_SIMILARITY_FILTER,
    payload: data
})


export const updateAlertsStatusPath = (data) => ({
    type: C.UPDATE_ALERTS_STATUS_PATH,
    payload: data
})

// Action to update data at a specific index in the smartImportSheetsData array
export const updateSmartImportSheetsData = (data) => ({
    type: C.UPDATE_SMART_IMPORT_SHEETS_DATA,
    payload: data
});

export const updatePatentsProductsMappingData = (data) => ({
    type: C.UPDATE_PATENTS_PRODUCTS_MAPPING_DATA,
    payload: data
});

export function updateClaimChartsEnable(status){
    if(status)
        return { type: C.SET_CLAIM_CHARTS_ENABLE }
    else
        return { type: C.UNSET_CLAIM_CHARTS_ENABLE }
}

export function updateProductsEnable(status){
    if(status)
        return { type: C.SET_PRODUCTS_ENABLE }
    else
        return { type: C.UNSET_PRODUCTS_ENABLE }
}

export function updateLevel2AnalysisLoading(status){
    if(status)
        return { type: C.SET_LEVEL2_ANALYSIS_ENABLE }
    else
        return { type: C.UNSET_LEVEL2_ANALYSIS_ENABLE }
}

export const updateLevel2SelectedPatents = (data) => ({
    type: C.UPDATE_LEVEL2_SELECTED_PATENTS,
    payload: data
});

export const updateisClaimChartGenerationLoading = (data) => ({
    type: C.UPDATE_CLAIM_CHART_GENERATION_LOADING,
    payload: data
});

export const updateProjectNotificationCount = (data) => ({
    type: C.UPDATE_PROJECT_NOTIFICATION_COUNT,
    payload: data
});

export const updateCurrentSearch = (data) => ({
    type: C.UPDATE_CURRENT_SEARCH,
    payload: data
})

export const updateStoreSearchQueryRunning = (data) => ({
    type: C.UPDATE_RUN_STORE_SEARCH_QUERY,
    payload: data
})

export const updateAppliedStoredSearch = (data) => ({
    type: C.UPDATE_APPLIED_STORED_SEARCH,
    payload: data
})

export const updateClaimChartsData = (data) => ({
    type: C.UPDATE_CLAIM_CHARTS_DATA,
    payload: data
});

export const set = (key, value) => localStorage.setItem(key, value);
export const get = (key) => localStorage.getItem(key);
export const remove = (key) => localStorage.removeItem(key);
export const clear = () => localStorage.clear();

export const checkUserSession = (data, dev) => {

    if(dev || (data.userData && data.userInfo && data.userInfo.login !== '')){

      let data = {
        
      };

      return { status : true, data }

    } else {
      return { status : false, data: ''}
    }

}
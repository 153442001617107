import React, { Component } from 'react';
import counterpart from 'counterpart';

import axios from 'axios';
import './index.scss';

import 'bootstrap/dist/css/bootstrap.min.css';
// import '@fortawesome/fontawesome/styles.css';
import '@fortawesome/fontawesome-svg-core/styles.css'; // Correct path for FontAwesome styles


import '../node_modules/react-grid-layout/css/styles.css';
import '../node_modules/react-resizable/css/styles.css';
import './js/ion.rangeSlider.js';
import './css/ion.rangeSlider.css';
import "react-datepicker/dist/react-datepicker.css";

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import './js/jquery.min.js';
// import './js/d3.min.js';

import 'react-dropzone-uploader/dist/styles.css';
import 'react-circular-progressbar/dist/styles.css';

import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

// import en from './components/languages/en';
// import zh from './components/languages/zh';

import Home from './home';
import storeFactory from './store';
import { Provider } from 'react-redux';
import storeProvider from './store/storeProvider';
import { BrowserRouter } from 'react-router-dom';


// counterpart.registerTranslations('en', en); 
// counterpart.registerTranslations('zh', zh); 


// Import i18n configuration
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n.js'; 


class App extends Component {

    constructor(props){
        super(props);
        this.state = {
            config:{}, 
            doneFetchingData: false
        }
    }

    componentDidMount(){
        this.getConfig();
    }

    getConfig = () => {
        var thisView = this;
        axios.get('configure.json')
        .then(function(res) {

            var config = res.data;
            thisView.setState({ config, doneFetchingData: true });
            
        }).catch(function(res) {
            console.log(res);
            console.log('An error occurred config in appjs');
        });
    }

    render() {

        const { config, doneFetchingData } = this.state;

        storeProvider.init(storeFactory);
        const store = storeProvider.getStore();
        const saveState = () =>
            localStorage.setItem(config.reduxkey, JSON.stringify(store.getState()));
        store.subscribe(saveState);

        // console.log("basepath :", config.basepath);

        return ( 
            doneFetchingData ?
            <I18nextProvider i18n={i18n}>
                {doneFetchingData ? (
                    <BrowserRouter basename={config.basepath}>
                        <Provider store={store}>
                            <ToastContainer />
                            <Home />
                        </Provider>
                    </BrowserRouter>
                ) : null}
            </I18nextProvider>
            : ''
        );

    }
}

export default App;

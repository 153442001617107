import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import registerServiceWorker from './registerServiceWorker';

const container = document.getElementById('root');
const root = createRoot(container);

// Define the root component without React.StrictMode for debugging
const RootComponent = () => (
  <App />
);

// Render the RootComponent to the root
root.render(<RootComponent />);

// Register the service worker
registerServiceWorker();
